import React, { useState, useEffect } from "react";
import { SharedService } from "../../Shared/InvestorShared.service";
import {
  Row,
  Col,
  Card,
  Typography,
  Table,
  Button,
  Form,
  Divider,
  Input,
  Progress,
  message,
  Result,
  Popconfirm,
  List,
  Modal,
  DatePicker,
  notification,
  Spin,
} from "antd";
import { InvestorService } from "../Investor.service";
import { AuthService } from "../../Shared/Auth.service";
// import PaymentOptions from './PaymentOptions'
import CompanyDetails from "./CompanyDetails";
import KYCUniqueURLCard from "../KYCUniqueURLCard";
import Payment from "./Payment";
import Process from "../Process";
import eversign from "./Eversign";
import ProcessCheckbox from "../ProcessCheckbox/";
const { Title } = Typography;
const investorService = new InvestorService();
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const sharedService = new SharedService();

export default function Home() {
  const [tokens, setTokens] = useState(null);
  const [presentToken, setPresentToken] = useState(null);
  const [KYCView, setKYCView] = useState(false);
  const [aiVerificationForm] = Form.useForm();
  const { userInfo, setUserInfo } = useUserContext();
  const [KYCSubmitted, setKYCSubmitted] = useState(false);
  const [heading, setHeading] = useState("Home");
  const [AIInfoView, setAIInfoView] = useState(true);
  const [AIVerificationSubmited, setAIVerificationSubmited] = useState(false);
  const [tAndCView, setTAndCView] = useState(false);
  const [disclaimerDocument, setDisclaimerDocument] = useState(false); 
  const [submitting, setSubmitting] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [AIView, setAIView] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  const [AISuccessView, setAISuccessView] = useState(false);
  const [paymentView, setPaymentView] = useState(false);
  const [paymentApproved, setPaymentApproved] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [signURL, setSignURL] = useState(null);
  const [signURLDis, setSignURLDis] = useState(null);
  const [tokensName, setTokenNames] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [documentsToSubmit, setDocumentsToSubmit] = useState([]);
  const [tokenDetails, setTokenDetails] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [modalView, showModal] = useState(false);
  const [aiData, setAIData] = useState(null);
  const [KYCLink, setKYCLink] = useState(null);
  const [currentlyInvestingIn, setCurrentlyInvestingIn] = useState(null);
  const [oldAISubmissionView, setOldAISubmissionView] = useState(false);
  const [paymentNotAcceptedView, setPaymentNotAcceptedView] = useState(false);
  const [AIDocumentsSubmittedView, setAIDocumentsSubmittedView] =
    useState(false);
  const [aiDocument, setAiDocument] = useState({
    aiDocument: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });
  const [openEversign, setOpenEversign] = useState(false);
  const [openEversignDis, setOpenEversignDis] = useState(false);

  const columns = [
    {
      title: "ISSUER",
      dataIndex: "issuerInfo",
      key: "issuer",
      render: (text) => <p>{text["0"]?.companyName}</p>,
    },
    {
      title: "TOKEN Symbol",
      dataIndex: "_id",
      key: "tokenSymbol",
      render: (text) => tokenDetails.get(text)[0]?.tokenSymbol,
    },
    {
      title: "PRICE PER TOKEN (cents)",
      dataIndex: "pricePerToken",
      key: "pricePerToken",
    },
    {
      title: "TOKEN NAME",
      dataIndex: "",
      render: (index, token) => {
        index.tokenName = tokensName.get(index.tokenConfigurationProcessId);
        return index.tokenName;
      },
    },
    {
      title: "ACTION",
      key: "action",
      render: (text, token) => {
        return (
          <>
            <Button type="primary" onClick={() => buyToken(token)}>
              Invest
            </Button>
          </>
        );
      },
      //render:(text,token)=><Button onClick={()=>buyToken(token)} >Invest</Button>
    },
  ];

  useEffect(() => {
  	setOpenEversign(signURL && tAndCView && heading === "NDA")
  }, [tAndCView,signURL,heading])

  useEffect(() => {
    console.log(signURLDis,"signURLDis")
  	setOpenEversignDis(signURLDis && disclaimerDocument && heading === "Disclaimer Document")
  }, [signURLDis, disclaimerDocument, heading])

  useEffect(() => {
    (async () => {
      const response = await investorService.getPayments({
        investorId: userInfo._id,
      });
      if (response.success && response.data) {
        if (response.data.length <= 0) {
          setPaymentApproved(false);
        } else {
          let paymentAccepted = response.data[0].accept;
          setPaymentApproved(paymentAccepted);
        }
      }
    })();
  }, [userInfo]);

  //USE EFFECT OF TOKEN,TOKEN DETAILS AND BANK DETAILS OF ISSUER RETRIEVAL
  useEffect(() => {
    (async () => {
      const response = await investorService.getTokens();
      if (response.success && response.data) {
        let temp = null;
        if (userInfo.country.toLowerCase() === "united states of america") {
          temp = await response.data.filter(
            (token) => token.issuerInfo[0]?.regulation === "regulation-d"
          );
        } else {
          temp = await response.data.filter(
            (token) => token.issuerInfo[0]?.regulation === "regulation-s"
          );
        }
        console.log(temp);
        setTokens(temp);
      } else {
        console.log("error");
        console.log(response);
        setTokens(null);
      }
      const tokenDetails = await investorService.getTokenDetails();
      if (tokenDetails.success && tokenDetails.data) {
        const tokensMap = new Map();
        tokenDetails.data.forEach((token) => {
          tokensMap.set(token._id, token.tokenDetails);
        });
        setTokenDetails(tokensMap);
      }
      const bankDetails = await investorService.getBankDetails();
      if (bankDetails.success && bankDetails.data) {
        const bankDetailsMap = new Map();
        bankDetails.data.forEach((token) =>
          bankDetailsMap.set(token._id, token.bankDetails)
        );
        setBankDetails(bankDetailsMap);
      }
      const tokensName =
        await investorService.getCompaniesAndTokenInfo();
      if (tokensName.success && tokensName.data) {
        console.log(tokensName.data);
        const tokenNameMap = new Map();
        tokensName.data.forEach((token) =>
          tokenNameMap.set(token._id, token.tokenInfo.name)
        );
        setTokenNames(tokenNameMap);
      }
    })();
  }, []);
  //USE EFFECT OF TOKEN,TOKEN DETAILS AND BANK DETAILS OF ISSUER RETRIEVAL ENDS HERE

  //USE EFFECT OF SIGN URL RETRIEVAL
  useEffect(() => {
    (async () => {
      if (userInfo.tAndCSigned === true) return;
      const response = await investorService.getSignURL(userInfo);
      console.log('something here ',response)
      await setSignURL(response.data);
    })();
  }, []);
  //USE EFFECT OF SIGN URL RETRIEVAL ENDS HERE

  //USE EFFECT OF DISCLAIMER RETRIEVAL
  useEffect(() => {
    (async () => {
      if (userInfo.disclaimerDocument === true) return;
      const response = await investorService.getSignDisclaimer(userInfo);
      await setSignURLDis(response.data);
    })();
  }, []);
  //USE EFFECT OF DISCLAIMER RETRIEVAL ENDS HERE

  //USE EFFECT OF AI DETAILS RETRIEVAL
  useEffect(() => {
    (async () => {
      const response = await investorService.getAiDetails({
        investorId: userInfo._id,
      });
      if (response.success && response.data) {
        if (response.data.length <= 0) {
          setAIVerificationSubmited(false);
        } else {
          setAIVerificationSubmited(true);
          setAIData(response.data[0]);
        }
      }
    })();
  }, []);
  //USE EFFECT OF AI DETAILS RETRIEVAL ENDS HERE.

  //display details starts
  const displayDetails = (token) => {
    showModal(true);
    setModalData(
      Object.assign(token, { tokenDetails: tokenDetails.get(token._id)[0] })
    );
  };
  //display details end

  const generateLinkForKYC = async (company) => {
    let dataToSend = {
      _id: userInfo._id,
      email: userInfo.email,
      role: userInfo.role,
      company,
    };
    const response = await investorService.generateLinkForKYC(dataToSend);
    if (response.success && response.data) {
      await setKYCLink(response.data);
    }
  };
  const getInvestorsCount = async (company) => {
    try {
      const response = await investorService.getInvestorsCount({ company });
      if (response.success && response.data) {
        return response.data.length;
      }
    } catch (e) {
      console.log("getInvestorsCount");
      console.log(e);
      message.error("can't fetch details of token");
      return -1;
    }
    return -1;
  };
  //buy token starts
  const buyToken = async (token) => {
    showModal(false);
    console.log("BUY TOKEN", token);
    await setPresentToken(token);
    setCurrentToken(token);
    //await Object.assign(token, {tokenDetails:tokenDetails.get(token._id)[0]});
    await setCurrentlyInvestingIn(token?.issuerInfo[0]?.companyName);
    if (!token) return;
    let investorsCount = await getInvestorsCount(
      token?.issuerInfo[0]?.companyName.toLowerCase()
    );
    console.log(investorsCount);
    if (investorsCount === -1) {
      return;
    }
    if (investorsCount >= token.totalInvestors) {
      message.info(
        "You can not invest in the token as total number of investors set by issuers is fullfield"
      );
      return;
    }
    if (userInfo.tAndCSigned === false) {
      switchToTandCView();
      return;
    }

    if (userInfo.disclaimerDocument === false) {
      switchToDisclaimerDocumentView();
      return;
    }
    let kycCheck = new Set(userInfo.KYCSubmittedCompany);
    let kycResult = kycCheck.has(
      token.issuerInfo[0].companyName.toLowerCase().trim()
    );

    let kycResubmitCompanySet = new Set(userInfo.KYCResubmitCompany);
    let kycResubmitCheck = kycResubmitCompanySet.has(
      token.issuerInfo[0].companyName.toLowerCase()
    );

    if(!kycResult) {
      await generateLinkForKYC(token.issuerInfo[0].companyName.toLowerCase());
      switchToCompleteKYCView();
      return;
    }

    if (!userInfo.isAccredited) {
      switchToAIView();
      return;
    }
    
    if (userInfo.country.toLowerCase() === "united states of america") {
      if (kycResult === true) {
        if (!userInfo?.paymentDone || userInfo.isAccredited) {
          setCurrentToken(token);
          switchToPaymentsView();
          return;
        } else {
          let aiCheck = new Set(userInfo.aiSubmittedCompany);
          let aiResult = aiCheck.has(
            token.issuerInfo[0].companyName.toLowerCase()
          );
          aiCheck = new Set(userInfo.aiSubmittedCompany);
          aiResult = aiCheck.has(token.issuerInfo[0].companyName.toLowerCase());
          let aiResubmitCompanySet = new Set(userInfo.aiResubmitCompany);
          let aiResubmitCheck = aiResubmitCompanySet.has(
            token.issuerInfo[0].companyName.toLowerCase()
          );
          if (aiResubmitCheck) {
            setAIVerificationSubmited(false);
            switchToAIView();
            notification.info({
              message: "Resubmit Accredited Investor Verification",
              description: `Issuer(${token.issuerInfo[0].companyName.toLowerCase()}) ask you to resubmit your Accredited Investor Verification details`,
            });
            return;
          }
          if (aiResult) {
            switchToAIDocumentSubmittedView();
            return;
          } else {
            if (AIVerificationSubmited) {
              switchToOldAISubmissionView();
              return;
            } else {
              switchToAIView();
              return;
            }
          }
        }
      }
      if (kycResubmitCheck) {
        await generateLinkForKYC(token.issuerInfo[0].companyName.toLowerCase());
        switchToCompleteKYCView();
        return;
      }
      kycCheck = new Set(userInfo.KYCSubmittedCompany);
      kycResult = kycCheck.has(token.issuerInfo[0].companyName.toLowerCase());
      // if (kycResult) {
      //   switchToKYCSubmittedView();
      //   return;
      // }

      if (kycResult === false) {
        await generateLinkForKYC(token.issuerInfo[0].companyName.toLowerCase());
        switchToCompleteKYCView();
        return;
      }
    } else {
      if (kycResult) {
        if ((!userInfo.paymentDone || paymentApproved)) {
          setCurrentToken(token);
          switchToPaymentsView();
          return;
        }
        if (userInfo.paymentDone && !paymentApproved) {
          switchToPaymentNotAcceptedView();
          return;
        }
      }
      switchToTableView();
      //   if(){

      //  }
    }
  };
  // buy tokens end

  // AI View
  const switchToAIView = () => {
    setHeading("Accredited Investor Verification");
    setTableView(false);
    setOldAISubmissionView(false);
    setKYCView(false);
    setAISuccessView(false);
    setPaymentView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIView(true);
    setTAndCView(false);
    setDisclaimerDocument(false);
    setPaymentNotAcceptedView(false);
  };
  //AI view ends

  const switchToPaymentNotAcceptedView = () => {
    setHeading("Payment Info");
    setTableView(false);
    setOldAISubmissionView(false);
    setKYCView(false);
    setAISuccessView(false);
    setPaymentView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIView(false);
    setTAndCView(false);
    setDisclaimerDocument(false);
    setPaymentNotAcceptedView(true);
  };

  // T and C view starts
  const switchToTandCView = () => {
    setHeading("NDA");
    setTableView(false);
    setOldAISubmissionView(false);
    setKYCView(false);
    setAISuccessView(false);
    setPaymentView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIView(false);
    setTAndCView(true);
    setDisclaimerDocument(false);
    setPaymentNotAcceptedView(false);
  };
  // T and C view ends


  // Reg D Term starts
  const switchToDisclaimerDocumentView = () => {
    setHeading("Disclaimer Document");
    setTableView(false);
    setOldAISubmissionView(false);
    setKYCView(false);
    setAISuccessView(false);
    setPaymentView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIView(false);
    setTAndCView(false);
    setDisclaimerDocument(true);
    setPaymentNotAcceptedView(false);
  };
  // Reg D Term ends

  // AI Submitted View
  const switchToAIDocumentSubmittedView = () => {
    setHeading("Accredited Investor Verification");
    setTableView(false);
    setOldAISubmissionView(false);
    setKYCView(false);
    setPaymentView(false);
    setAIView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(true);
    setTAndCView(false);
    setDisclaimerDocument(false);
    setPaymentNotAcceptedView(false);
  };
  // AI Submitted ends

  //TABLE VIEW
  const switchToTableView = () => {
    setHeading("HOME");
    setOldAISubmissionView(false);
    setKYCView(false);
    setPaymentView(false);
    setAIView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(false);
    setTableView(true);
    setTAndCView(false);
    setDisclaimerDocument(false);
    setPaymentNotAcceptedView(false);
  };
  // //KYC SubmittedView
  // const switchToKYCSubmittedView = () => {
  //   setHeading("KYC DETAILS");
  //   setOldAISubmissionView(false);
  //   setKYCView(false);
  //   setPaymentView(false);
  //   setAIView(false);
  //   setAISuccessView(false);
  //   setAIDocumentsSubmittedView(false);
  //   setTableView(false);
  //   setKYCSubmitted(true);
  //   setTAndCView(false);
  // setPaymentNotAcceptedView(false);
  // };

  const switchToCompleteKYCView = () => {
    setHeading("SUBMIT KYC");
    setOldAISubmissionView(false);
    setKYCView(false);
    setPaymentView(false);
    setAIView(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(false);
    setTableView(false);
    setKYCSubmitted(true);
    setTAndCView(false);
    setDisclaimerDocument(false);
    setPaymentNotAcceptedView(false);
  };
  //KYC Submitted view

  //OLD AI Submission View
  const switchToOldAISubmissionView = () => {
    setHeading("Accredited Investor Verification");
    setKYCView(false);
    setPaymentView(false);
    setAIView(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(false);
    setTableView(false);
    setKYCSubmitted(false);
    setOldAISubmissionView(true);
    setTAndCView(false);
    setDisclaimerDocument(false);
    setPaymentNotAcceptedView(false);
  };

  // OLD AI SUBMISSION VIEW ENDS

  //Payment View
  const switchToPaymentsView = () => {
    setHeading("");
    setKYCView(false);
    setAIView(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(false);
    setTableView(false);
    setKYCSubmitted(false);
    setOldAISubmissionView(false);
    setPaymentView(true);
    setTAndCView(false);
    setDisclaimerDocument(false);
    setPaymentNotAcceptedView(false);
  };

  // Payment View ENDS

  //save Ai details starts
  const saveAIVerificationDetails = async (data) => {
    setSubmitting(true);
    let todaysDate = new Date();
    console.log(todaysDate.getFullYear());
    if (
      data.aiVerificationDate.toDate().getFullYear() !==
      todaysDate.getFullYear()
    ) {
      message.error(
        "Accredited Investor Verification should be of current financial year."
      );
      setSubmitting(false);
      return;
    }
    data.aiVerificationDate = data.aiVerificationDate.toDate().toDateString();
    data.creationTS = Date.now();
    data.aiDocument = documentsToSubmit;
    data.verified = false;
    data.rejected = false;
    data.resubmit = false;
    data.investorId = userInfo._id;
    if (userInfo.companyName && userInfo?.companyName?.trim()?.length !== 0)
      data.company = userInfo.companyName.toLowerCase();
    else data.company = currentlyInvestingIn.toLowerCase();
    data.firstName = userInfo.firstName;
    data.lastName = userInfo.lastName;
    data.email = userInfo.email;
    data.contactNumber = userInfo.contactNumber;
    data.creationTS = Date.now();
    let temp = Object.assign(
      {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        country: userInfo.country,
        contactNumber: userInfo.contactNumber,
      },
      data
    );
    console.log(temp);
    const response = await investorService.submitAIDetails(temp);
    if (response.data && response.success) {
      await userInfo.aiSubmittedCompany.push(
        currentlyInvestingIn.toLowerCase()
      );
      userInfo.aiResubmitCompany = await userInfo.aiResubmitCompany.filter(
        (company) =>
          company.toLowerCase() !== currentlyInvestingIn.toLowerCase()
      );
      setAIVerificationSubmited(true);
      //
      userInfo.isAccredited = true;
      setUserInfo({...userInfo});
      //
      switchToAIDocumentSubmittedView();
    }
    setSubmitting(false);
  };
  //save ai details ends

  //SAVE OLD AI DATA STARTS
  const saveOldAIVerificationDetails = async (data) => {
    setSubmitting(true);
    data.verified = false;
    data.rejected = false;
    data.resubmit = false;
    data.investorId = userInfo._id;
    data.creationTS = await Date.now();
    if (userInfo.companyName && userInfo?.companyName?.trim()?.length !== 0)
      data.company = currentlyInvestingIn.toLowerCase();
    else data.company = currentlyInvestingIn.toLowerCase();
    let temp = Object.assign(
      {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        country: userInfo.country,
        contactNumber: userInfo.contactNumber,
      },
      data
    );
    console.log(temp);
    const response = await investorService.submitAIDetails(temp);
    if (response.data && response.success) {
      userInfo.aiSubmittedCompany.push(currentlyInvestingIn.toLowerCase());
      setAIVerificationSubmited(true);
      switchToAIDocumentSubmittedView();
    }
    setSubmitting(false);
  };
  //SAVE OLD AI DATA ENDS

  // DELETE UPLOADED FILE STARTS
  const deleteItem = (index) => {
    setDocuments(documents.filter((document) => document !== documents[index]));
    setDocumentsToSubmit(
      documentsToSubmit.filter(
        (document) => document !== documentsToSubmit[index]
      )
    );
  };
  // DELETE UPLOADED FILE ENDS HERE

  //UPLOAD FILE STARTS HERE
  const uploadFile = (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    const fileType = sharedService.getFileType(file.name);
    setFilesObj((prev) => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    investorService.uploadFile(
      file,
      (uploadFilePercent) => {
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = true;
          current[key].uploadFilePercentage = uploadFilePercent;
          return current;
        });
      },
      (err) => {
        console.error(err);
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      },
      async (response) => {
        if (response.success) {
          const URL = response.data.url;
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].URL = URL;
            current[key].uploading = false;
            current[key].name = file.name;
            current[key].type = fileType.toLowerCase();
            return current;
          });
          let fileDetails = { [key]: { URL, name: file.name } };
          form.setFieldsValue(fileDetails);
          setDocuments((prev) => prev.concat(file.name));
          setDocumentsToSubmit((prev) => prev.concat(fileDetails));
        } else {
          console.error(response.error);
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].uploading = false;
            return current;
          });
        }
      }
    );
  };
  //UPLOAD FILE ENDS HERE

  //ACCEPT T AND C STARTS
  const acceptTandC = async () => {
    const response = await investorService.acceptTandC({
      email: userInfo.email,
    });
    if (response.success && response.data) {
      userInfo.tAndCSigned = true;
      setUserInfo(userInfo);
      buyToken(presentToken);
    }
  };
  //ACCEPT T AND C ENDS
  
  //ACCEPT DESCLAIMER STARTS
  const acceptDisclaimerDocument = async () => {
    const response = await investorService.acceptDisclaimerDocument({
      email: userInfo.email,
    });
    if (response.success && response.data) {
      userInfo.disclaimerDocument = true;
      setUserInfo(userInfo);
      buyToken(presentToken);
    }
  };
  //ACCEPT DESCLAIMER ENDS

  //HELO SIGN API CALL STARTS
  const onSign = () => {
    console.log("here")
    setTAndCView(false);
    acceptTandC();
  };
  //HELO SIGN API CALL ENDS


  //HELO SIGN Disclimanr API CALL STARTS
  const onSignDis = () => {
    setDisclaimerDocument(false);
    acceptDisclaimerDocument();
  };
  //HELO SIGN Disclimanr API CALL ENDS

  //VIEW AFTER AI AND KYC ACCEPTED
  const paymentBackCallback = () => {
    userInfo.paymentDone = true;
    buyToken(presentToken);
  };
  //INVEST IN TOKENS ENDS HERE

  useEffect(() => {
    if (openEversign && signURL) {
      eversign.open({
        url: signURL,
        containerID: "embedSignDivision",
        width: 750,
        height: 800,
        events: {
          loaded: () => console.log("load hogya"),
          signed: onSign,
          error: () => console.log("EVERSIGN ERROR"),
          declined: () => console.log("declined"),
        },
      });
    }
  }, [openEversign, signURL]); 

  return (
    <>
      <br />
      <Row justify="center">
        <Col span={18} style={{ textAlign: "center" }}>
          <Card>
            <Process key={Date.now()} />
            <Divider />
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              {heading}
            </Title>
            <Spin size="large" spinning={!tokenDetails || !tokensName}>
              <div hidden={!tableView}>
                {tokenDetails && tokensName && (
                  <>
                  <Table
                    dataSource={tokens}
                    loading={!(tokens && tokenDetails)}
                    columns={columns}
                    pagination={false}
                  />
                  <br />
                  {/* <Card>
                  <h3><b>User Guide for Fund Transfer</b></h3>
                  <>
                  <Row>
                     <Col>
                	    <b>1.Investment process guide</b>
                     </Col>
                     <Col>
				              <a target="#" href={require("../../../assets/Documents/Investment Process.pdf")}>&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;Investment Process.pdf</a>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                	    <b>2.Skrill Payment Guide</b>
                     </Col>
                     <Col>
				              <a target="#" href={require("../../../assets/Documents/Skrill.pdf")} >&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;&nbsp;Skrill.pdf</a>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                	    <b>3.Western Union Payment Guide</b>
                     </Col>
                     <Col>
				              <a target="#" href={require("../../../assets/Documents/WESTERN MONEY UNION.pdf")}>&emsp;WESTERN MONEY UNION.pdf</a>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                	    <b>4.HDFC bank payment Guide</b>
                     </Col>
                     <Col>
				              <a target="#" href={require("../../../assets/Documents/HDFC.pdf")} >&emsp;&emsp;&nbsp;&nbsp;&nbsp;HDFC.pdf</a>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                	    <b>5.ICICI bank Payment Guide</b>
                     </Col>
                     <Col>
				              <a target="#" href={require("../../../assets/Documents/ICIC Bank.pdf")} >&emsp;&emsp;&nbsp;&nbsp;&nbsp;&nbsp;ICIC Bank.pdf</a>
                     </Col>
                  </Row>
                  </>

                  </Card> */}
                  </>
                )}
              </div>
            </Spin>

            {/* USA Investors AI Upload View */}
            <div hidden={!AIView}>
              <div hidden={!AIInfoView}>
                {/* <h5>
                  If you have not completed your Accredited investor
                  verification complete it by clicking the below given link.
                  <br /> You can also get your accredited investor verification
                  done from your chartered accoutant and upload the document
                  below.
                </h5>
                <br />
                <a
                  href="https://www.earlyiq.com/partner/cryptoassetrating/investor/one/getstarted"
                  rel="noopener norefferer"
                  target="_blank"
                >
                  Click the link to complete Accredited investor verification
                </a> */}

                {/* <Divider>OR</Divider> */}
                <h5>
                Please upload the Accredited Investor Verification document by clicking the button given below
                </h5>
                <br />
                <Button type="primary" onClick={() => setAIInfoView(false)}>
                  Upload document here
                </Button>
              </div>

              <div hidden={AIInfoView}>
                <h5>
                  To begin investing in Reg D Token, first get approved for your
                  Accredited Status. Submit the verified results for your
                  Accredited Status here.
                </h5>
                <Divider />
                <Form
                  form={aiVerificationForm}
                  onFinish={saveAIVerificationDetails}
                >
                  <Form.Item
                    label="Accredited Investor Verification done on"
                    name="aiVerificationDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select date !",
                      },
                    ]}
                  >
                    <DatePicker disabled={AIVerificationSubmited}></DatePicker>
                  </Form.Item>

                  <Form.Item
                    label="Accredited Investor Verification Documents"
                    name="aiDocument"
                    rules={[
                      {
                        required: true,
                        message: "Please select atleast one document !",
                      },
                    ]}
                  >
                    <Input
                      type="file"
                      onChange={(e) => {
                        uploadFile(
                          e,
                          "aiDocument",
                          setAiDocument,
                          aiVerificationForm
                        );
                      }}
                      disabled={AIVerificationSubmited}
                    />
                    {aiDocument.aiDocument.uploadFilePercentage > 0 && (
                      <>
                        <Progress
                          percent={aiDocument.aiDocument.uploadFilePercentage}
                        />
                        <br />
                        <div align="left">
                          Files Uploaded:
                          <List
                            itemLayout="horizontal"
                            style={{ textAlign: "left" }}
                          >
                            {documents?.map((obj, index) => (
                              <List.Item
                                textalign="left"
                                style={{ cursor: "pointer" }}
                                key={index}
                                actions={[
                                  <Popconfirm
                                    title="This item will be deleted!"
                                    onConfirm={() => deleteItem(index)}
                                  >
                                    <Button type="primary" danger>
                                      X
                                    </Button>
                                  </Popconfirm>,
                                ]}
                              >
                                <List.Item.Meta title={obj} />
                              </List.Item>
                            ))}
                          </List>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitting}
                      disabled={AIVerificationSubmited}
                    >
                      Submit
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      loading={submitting}
                      htmlType="button"
                      onClick={() => {
                        aiVerificationForm.resetFields();
                        setDocuments([]);
                        setDocumentsToSubmit([]);
                      }}
                      disabled={AIVerificationSubmited}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button onClick={() => setAIInfoView(true)}>Back</Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
            {/* USA Investors AI Upload ends here. */}

            {/* AI Submitted for the company */}
            <div hidden={!AIDocumentsSubmittedView}>
              <Result
                title="Successfully Submitted!"
                subTitle="your Accredited Investor Verification documents are successfully submitted."
                extra={[
                  <Button type="primary" onClick={() => buyToken(presentToken)}>
                    NEXT
                  </Button>,
                ]}
              ></Result>
            </div>
            {/*AI submitted for the company ends  */}

            {/* Terms and Conditions View Starts Here */}
            <div hidden={!tAndCView} id="embedSignDivision">
              <div id="embedSignDivision" />
            </div>
            {/* Terms and Conditions View ends here */}

            {/*Disclaimer Document View Starts Here */}
            <div hidden={!disclaimerDocument} id="embedSignDivision2">
              <>
                {openEversignDis &&
                  eversign.open({
                    url: signURLDis,
                    containerID: "embedSignDivision2",
                    width: 750,
                    height: 800,
                    events: {
                      loaded: () => console.log("load hogya"),
                      signed: onSignDis,
                      error: () => console.log("EVERSIGN ERROR"),
                      declined: () => console.log("declined"),
                    },
                  })}
              </>
            </div>
            {/* Disclaimer Document View ends here */}

            {/* USA Investors AI Success View */}
            <div hidden={!AISuccessView}>
              <Result
                status="success"
                title="Accredited Investor Verification Successfull"
                subTitle={
                  <a href="/investor/KYCverification">
                    Please proceed to KYC Verification by clicking here!
                  </a>
                }
              ></Result>
            </div>
            {/* USA Investor AI sucess View ends here. */}
            <div hidden={!paymentNotAcceptedView}>
              <Result
                status="info"
                title="Your payment is not accepted yet.Please wait for approval to do next investment."
              ></Result>
            </div>
            {/* Non USA Investor KYC view */}
            <div hidden={!KYCView}>
              <h2>Once KYC is approved you will not see this page.</h2>
              <br />
              <a href="/investor/KYCverification">
                Please proceed to KYC Verification by clicking here!
              </a>
            </div>
            {/* Non USA Investor KYC View end here */}

            {/* KYC SUBMITTED FOR COMPANY */}
            <div hidden={!KYCSubmitted}>
              {KYCLink && <KYCUniqueURLCard uniqueURL={KYCLink} />}
            </div>
            {/* KYC SUBMITTED FOR COMPANY ENDS*/}

            {/* Payment View */}
            <div hidden={!paymentView}>
              {paymentView && (
                <Payment
                  user={userInfo}
                  onBack={paymentBackCallback}
                  currentToken={currentToken}
                />
              )}
            </div>
            {/* payment View ends here */}

            {/* Issuer Company Details */}
            <Modal
              title="Token Issuer Details"
              closable={false}
              visible={modalView}
              footer={
                <Button
                  htmlType="button"
                  type="primary"
                  onClick={() => showModal(false)}
                >
                  OK
                </Button>
              }
              width={1024}
            >
              {modalView && (
                <CompanyDetails
                  company={modalData?.issuerInfo[0]?.companyName}
                  onBack={() => showModal(false)}
                  investButtonCallback={() => {
                    buyToken(modalData);
                  }}
                ></CompanyDetails>
              )}
            </Modal>
            {/* Issuer Company details end here */}

            {/* When AI verification is already submitted */}
            <div hidden={!oldAISubmissionView}>
              <Result
                status="success"
                title="You have already submitted your Accredited Investor Verification documents.Below is the list of your submitted document(s)."
                subTitle={aiData?.aiDocument.map((document, index) => {
                  return (
                    <div>
                      {index + 1}){" "}
                      <a href={document.aiDocument.URL} target="_blank">
                        {document.aiDocument.name}
                      </a>
                      <br />
                    </div>
                  );
                })}
                extra={[
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      saveOldAIVerificationDetails(aiData);
                      setOldAISubmissionView(false);
                    }}
                    loading={submitting}
                  >
                    Submit Existing
                  </Button>,
                  <Button onClick={() => switchToAIView()} loading={submitting}>
                    Upload New
                  </Button>,
                ]}
              ></Result>
            </div>

            {/* AI Verification already submitted section ends here */}
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <ProcessCheckbox
              style={{ marginTop: "5%" }}
              key={Math.floor(Math.random() * 100)}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}
