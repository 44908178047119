import React, {useState, useEffect} from 'react';
import { Form, Input, Button, Card, Row, Col,Spin, Alert, message, Modal, } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory } from 'react-router-dom';
// import * as classNames from 'classnames';
import { SharedService } from '../../Shared/Shared.service';
import { AccountService } from '../Account.service';
import { AuthService } from '../../Shared/Auth.service';
import { environment } from '../../../environments/environment';
import './login.css';
import faq from '../../../assets/images/logo/faq.png'

const accountService = new AccountService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();
export default () => {
  const history = useHistory();
  
  const [reqErr, setReqErr] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [expired, setExpired] = useState(true);

  const {userInfo, setUserInfo} = useUserContext();
  const [forgotPasswordSubmitting, setForgotPasswordSubmitting] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
  const [form] = Form.useForm();
  
  useEffect(() => {
    if(!userInfo) return;
    if(userInfo.role === 'platform issuer admin') {
      history.push('/platform-issuer-admin/issuer-reg-reqs');      
    } else if(userInfo.role === 'issuer super admin') {
      history.push('/issuer-super-admin/basic-info-verif');
    } else if(userInfo.role === 'employee' || userInfo.role === 'contractor_consultant_Advisor') {
      history.push(`/team-member/vesting-schedule`);
    }else if(userInfo.role==='issuer token admin') {
      history.push('/token-dashboard');
    }
    else if(userInfo.role==='platform super admin') {
      history.push('/platform-super-admin/manage-api-key');
    } else if(userInfo.role === 'issuer counsel') {
      history.push('/token-configurations');
    }
    else if(userInfo.role === 'issuer company officer') {
      history.push('/token-configurations');
    }
    else if(userInfo.role==='investor')
    {
      history.push('/investor/home');
    }
    else if(userInfo.role==='platform compliance admin')
    {
      history.push('/platform-compliance-admin/kyc-verification-request')
    }
    else if(userInfo.role==='issuer compliance admin')
    {
      history.push('/issuer-compliance-admin/kyc-verification-request')
    }
    // const subscription = props.source.subscribe();
    // return () => {
    //   subscription.unsubscribe();
    // };
  },
  [userInfo, history],
);
    

  const handleCaptchaChange = (value: any) => {
    setExpired(false);
    // if value is null recaptcha expired
    if (value === null) setExpired(true);
  };
  
  const login = async ({email, password}: {[name: string]: string}) => {
    setSubmitting(true);
    
    // CHECK IF EMAIL IS VERIFIED
    try {
      const response = await accountService.login(email, password);
      
      if (response.success) {
        sharedService.setToken(response.data.token);
        setUserInfo({...response.data.user});
        
      } else {
        console.error(response.error.message);
        setReqErr(response.error.message);
      }

    } catch (err) {
      console.error(err);
      setReqErr('An error ocurred. Try again later');
    }

    setSubmitting(false);

  }

  const toggleForgotPasswordModal = () => {
    console.info("open modal");
    setIsForgotPasswordModalOpen(!isForgotPasswordModalOpen);
  };

  const handleSubmitForgotPassword = async (req) => {
    console.info({req});
    setForgotPasswordSubmitting(true);
    const response = await accountService.forgotPassword(req);
    if (response.success) {
      message.success(
        "Please check, you have received reset password link on your registered email address.",
        7
      );
      form.resetFields();
      setIsForgotPasswordModalOpen(false);
    } else {
      message.error(response.data, 5);
    }
    setForgotPasswordSubmitting(false);
  };

  return (
    <div className='login-wrapper'>
            <Row gutter={16} className='login-wrapper-row' align="middle" >
                <Col xs={24} sm={24} md={14} className='login-left-section'>
                  <div>
                  <span className='head-text'>Step Into Your Future with Confidence</span>
                  <h3 className='sub-text'>Start Your Journey Today.</h3>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={10}>
                <div className="login-form-wrapper">
                  <h2>LogIn</h2>
                    <Form
                      // form = {form}
                      // size='middle'
                      onFinish={login}
                      className='login-form'
                      // style={{margin: 'auto', marginTop: '3%', width: '60%',  textAlign: 'center'}}
                      >
                      <Form.Item
                        // label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                        validateStatus={reqErr && 'error'}
                        >
                        <Input size="large" className="rounded-input" prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder='Email'/>
                      </Form.Item>
            
                      <Form.Item
                        // label="Password"
                        name="password"
                        className='passwor-input'
                        rules={[{ required: true, message: 'Please input your password!' }]}
                        validateStatus={reqErr && 'error'}
                        help={reqErr}>
                        <Input.Password size="large" className="rounded-input" placeholder='Password' prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}/>
                      </Form.Item>
                        <a className='login-form-forgot' href="/account/login">
                          Forgot Password?
                        </a>
                      {/* <div style={{ marginTop: '30px', display: 'inline-block' }}>
                        <ReCAPTCHA
                          theme='light'
                          sitekey={environment.captchaKey}
                          onChange={handleCaptchaChange}/>
                      </div> */}
            
                      {/* <Form.Item> */}
                        <Button
                          type='primary'
                          htmlType='submit'
                          className='login-form-button'
                          loading={submitting}
                          size="large"
                          // disabled={expired}
                        >
                          Log in
                        </Button>
                        <span className='register-now'>Do you have an account? <Link to='/account/register/investor'>Register</Link></span>
                        {/* <br/> */}
                        {/* <>Or </> */}
                        <br/>
                        {/* <Link to='/account/resend-email-verification-code'>resend email verification link</Link> */}
                      {/* </Form.Item> */}
                    </Form>
                </div>


                </Col>
                <Link to='/faq' target='_blank'><img src={faq} style={{position: 'absolute',bottom: '20px',right: '45px',height: '75px'}} alt="" /></Link>
            </Row>
    </div>
  )
}